@import "configs/color.css";
@import "configs/breakpoints.css";
@import "../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
@import "../../node_modules/react-day-picker/lib/style.css";
@import "bootstrap_reset.css";

.container {
  /*font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;*/
  font-size: 14px;
  margin-left: 60px;
  margin-right: 60px;
}
@media only screen and (max-width: 768px) {
  .container {
    margin-left: 0;
    margin-right: 0;
  }
}

.content-wrapper {
  position: absolute;
  top: 75px;
  width: 100%;
  background: #f5f6f8;
}
.main-content{
    margin-left: 50px;
    flex:1;
    max-width:97%;
    @media (max-width: $sm) {
        margin-left: 0;
    }
}

body {
    background-color: white;
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
}

/*Style preloader*/
.loader-container{
  min-height: 140px;
}
.loader {
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid #3498db;
  width: 60px;
  height: 60px;
  left: 50%;
  background-color: #e0e0e0;
  position: absolute;
  margin: 1rem 1rem 1rem -30px;
  padding: 1rem;
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}
.small-loader {
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid #3498db;
  width: 30px;
  height: 30px;
  background-color: #e0e0e0;
  position: absolute;
  padding: 1rem;
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
  margin: 1rem 1rem 1rem -30px;
  left: 40%;
}
.ocultar{
    display: none !important;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

#main-logo{
    max-width: 40px;
}

@import "all.css";
@import "all2.css";

@import "components/buttons.css";
@import "components/navbar.css";
@import "components/sidebar.css";
@import "components/others.css";
@import "components/card.css";
@import "components/form.css";
@import "components/tables.css";
@import "components/textos.css";
@import "components/circular-bar.css";
@import "components/date-picker.css";
@import "components/progress-bar.css";
@import "components/impresion.css";
@import "components/google-maps.css";
@import "components/modal.css";
.error-template {padding: 40px 15px;text-align: center;}
.error-actions {margin-top:15px;margin-bottom:15px;}
.error-actions .btn { margin-right:10px; }



/*Sobre-escribiendo z-index para el swal*/
.swal2-container {
    z-index: 999999;
    overflow: hidden !important;
}

#swal2-content {
    width: 100% !important;
    text-align: center !important;
}

/*Importando estilos para rc-tabs*/
@import "../../node_modules/rc-tabs/assets/index.css";
@import "components/rc-tabs.css";

.curvas-top{
    position: fixed;
    right: 0;
    height: 35%;
}
a{
    color: $verde;
}
.action_img{
    height: 23px;
    cursor: pointer;
}
.title_img{
    height: 25px;
    @media (max-width: $md) {
        height: 16px;
    }
}

.dashboard_img{
    height: 35px;
    @media (max-width: $md) {
        height: 20px;
    }
}

.img-acopio-fluid{
    max-width: 100%;
    height: auto;
    border-radius: 0.625rem;
}

.barras-contador{
    height: 25px;
    width: 6px;
    margin-right: 2px;
    @media (max-width: $md) {
        height: 16px;
        width: 6px;
    }
}

.barras-contador-pequeñas{
    height: 25px;
    width: 4px;
    margin-right: 1px;
    @media (max-width: $md) {
        height: 16px;
        width: 4px;
    }
}

.step-number{
    background: white;
    border: 1px solid;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    font-size: 12px;
    font-weight: bold;
}


/*MODAL*/
.styles_modal__gNwvD {
    position: relative;
    width: 100%;
    border-radius: 1rem;
    padding: 1.2rem;
    border: 1px solid $azul;
    background: #fff;
    background-clip: padding-box;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    @media (max-width: $sm) {
        width: 100%;
    }
}

.contenido-contactos{
    max-height: 125px;
    overflow: auto;
}

.flex-1{
    flex: 1;
}
.ocultar{
    display: none !important;
}

/*PROGRESS BAR*/
.progress-container{
    width: 30%;
    @media (max-width: $md) {
        width: 50%;
    }
    @media (max-width: $sm) {
        width: 100%;
    }
}
.RSPBprogressBar{
    border-radius: 1rem !important;
}
.RSPBprogression{
    border-radius: 1rem !important;
}
.RSPBprogressBar .RSPBprogressBarText {
    font-size: 12px !important;
    font-weight: bold;
}
.text-cm-progress{
    font-size: 12px;
    font-weight: bold;
    color: $gris;
}
.text-in-progress{
    font-size: 12px !important;
    font-weight: bold;
    color: white;
    position: absolute;
    margin-top: -24px;
}
