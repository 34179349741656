.react-responsive-modal-root {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .react-responsive-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0  ;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  
  .react-responsive-modal-container {
    height: 100%;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
  }
  
  /* Used to trick the browser to center the modal content properly  */
  .react-responsive-modal-containerCenter:after {
    width: 0;
    height: 100%;
    content: '';
    display: inline-block;
    vertical-align: middle;
  }
  
  .react-responsive-modal-modal {
    max-width: 800px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    background: #ffffff;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    margin: 1.2rem;
    padding: 1.2rem;
    position: relative;
    overflow-y: auto;
  }
  
  .react-responsive-modal-closeButton {
    position: absolute;
    top: 14px;
    right: 14px;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    display: flex;
  }
  .customOverlay {
    background: rgba(36, 123, 160, 0.7);
  }
  .customModal {
    background: #b2dbbf;
    max-width: 500px;
    width: 100%;
  }
  /* Used to fix a screen glitch issues with the animation see https://github.com/pradel/react-responsive-modal/issues/495 */
  .react-responsive-modal-overlay,
  .react-responsive-modal-container,
  .react-responsive-modal-modal {
    animation-fill-mode: forwards !important;
  }
  
  @keyframes react-responsive-modal-overlay-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes react-responsive-modal-overlay-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes react-responsive-modal-modal-in {
    0% {
      transform: scale(0.96);
      opacity: 0;
    }
    100% {
      transform: scale(100%);
      opacity: 1;
    }
  }
  
  @keyframes react-responsive-modal-modal-out {
    0% {
      transform: scale(100%);
      opacity: 1;
    }
    100% {
      transform: scale(0.96);
      opacity: 0;
    }
  }

  @keyframes customEnterOverlayAnimation {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes customLeaveOverlayAnimation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  
  @keyframes customEnterModalAnimation {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes customLeaveModalAnimation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.2);
    }
  }
  .customModalSize {
    width: 1144px;
    height: 522px;
    border-radius: 30px;
    background: #FFF;
    margin: 10px;
  }

  .tinyModalSize {
    max-width: 420px;
    max-height: 300px;
    border-radius: 30px;
  }
  
  .btn-modal{
    width: 200px;
    height: 40px;
  }

  .alerta {
    max-width: 40rem;
    margin: 1rem auto 1rem auto;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 1rem;
    text-align: center;
}
.error_mensaje {
    background-color: var(--blanco);
    border-left: 5px solid #B91C1C;
    color: #B91C1C;
}

/* Estilos para la barra de desplazamiento del modal */
.customModalSizeLatex {
  width: 1244px !important; /* Asegúrate de que los estilos se apliquen */
  height: 680px !important;
  border-radius: 30px;
  background: #FFF;
  margin: 10px;
  max-width: 100%; /* Asegúrate de que no exceda el ancho del viewport */
  overflow-y: auto; /* Habilitar desplazamiento vertical */
}

.customModalSizeLatex::-webkit-scrollbar {
  width: 10px; /* Ancho de la barra de desplazamiento */
}

.customModalSizeLatex::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color del track de la barra de desplazamiento */
  border-radius: 10px;
}

.customModalSizeLatex::-webkit-scrollbar-thumb {
  background: #888; /* Color del thumb de la barra de desplazamiento */
  border-radius: 10px;
}

.customModalSizeLatex::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color del thumb al pasar el ratón */
}

/* Estilos para la barra de desplazamiento en Firefox */
.customModalSizeLatex {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* Para una mejor personalización en Firefox */
.customModalSizeLatex::-moz-scrollbar {
  width: 10px; /* Ancho de la barra de desplazamiento */
}

.customModalSizeLatex::-moz-scrollbar-track {
  background: #f1f1f1; /* Color del track de la barra de desplazamiento */
}

.customModalSizeLatex::-moz-scrollbar-thumb {
  background: #888; /* Color del thumb de la barra de desplazamiento */
  border-radius: 10px;
}
