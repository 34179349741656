:root {
    --primario-verde-oscuro : #0C3529;
    --complementario: #FFFF;
    --caja-tabla: #EBEBEB;

  }
.main-sidebar {
    top: 0;
    position: fixed;
    height: 100vh;
    background: transparent;
    z-index: 700;
    will-change: transform;
    transition: -webkit-transform .2s ease-in-out;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    width: 53px;
    transition: width 0.3s;
    @media (max-width: $sm) {
        position: fixed;
        width: 100%;
    }
}
.main-sidebar:hover {
    padding-right: 1rem;
    width: 257px;
    z-index: 700;
    @media (max-width: $sm) {
        width: 100%;
    }
}
/*OPCIONES DEL SUBMENU*/
.acordion-expanded{
    background-color: white;
    margin-top: 10px;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 5px 5px 5px 5px;
}
.acordion-expanded-1{
    background-color: white;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 5px 5px 5px 5px;
}
.sub-nav-link{
    color: var(--primario-verde-oscuro);
    padding: 0.5rem 0.7rem;
    display: flex;
    font-weight: 400;
    span{
        margin-left: 7px;
    }
    &.active{
        text-decoration: none;
        background-color: var(--caja-tabla);
        color:#0c3529;
        text-transform: uppercase;
        font-weight: 700;
     }
}
.sub-nav-link:not(a.active):hover{
    text-decoration: none;
    background-color:var(--caja-tabla);
    color: var(--primario-verde-oscuro);
    cursor: pointer;

}
.sub-nav-link-inactivo{
    color: var(--primario-verde-oscuro);
    padding: 0.5rem 0.7rem;
    display: flex;
    font-weight: 400;
    span{
        margin-left: 7px;
    }
}
.sub-nav-item-ch{
    display: flex;
    align-items: center;
    gap: 7px;
}
.elimpse{
    width: 10px;
    height: 10px
}
.br-top{
    height: 10px;
}
.main-sidebar.open {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}
.main-sidebar .toggle-sidebar {
    position: absolute;
    right: 0;
    height: 100%;
    padding: 1.25rem;
    font-size: 1.25rem;
    border-left: 1px solid #e1e5eb;
}
.main-sidebar .toggle-sidebar:hover {
    cursor: pointer
}
.main-sidebar .navbar-brand {
    /*overflow: hidden;*/
    height: 3.75rem;
    font-size: 1rem
}
.main-sidebar .nav-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 3.75rem - 40px);
    background-color: var(--primario-verde-oscuro);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding-top: 129px;
    padding-left: 10px;
    /* Ocultar el Scroll en Firefox, e Internet Explorer 10+ */
    scrollbar-width: none;
    -ms-overflow-style: none;
    /* Ocultar el Scroll en Chrome y Safari */
    &::-webkit-scrollbar {
        display: none;
    }
}
/* Colapsar el subitem mostrado al ocultar el Sidebar, y mostrarlo cuando se expanda el Sidebar */
.main-sidebar .nav-wrapper .nav-item .accordion__item .acordion-expanded{
    /* Cuando se oculta el Sidebar se colapsa el subitem seleccionado */
    height: 0px;
    overflow: hidden;
}
.main-sidebar .nav-wrapper .nav--no-borders .nav-item .accordion__item :focus {
    outline: none !important;
}
.main-sidebar .nav-wrapper:hover .nav-item .accordion__item .acordion-expanded{
    height: auto;
    overflow: auto;
}
.main-sidebar .nav .nav-item, .main-sidebar .nav .nav-link {
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
}
.main-sidebar .nav .nav-item .nav-link i {
    min-width: 1.25rem;
    font-size: 90%;
    text-align: center;
    vertical-align: middle;
    will-change: color;
    color: #cacedb;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
    margin-right: .375rem
}
.main-sidebar .nav .nav-item .nav-link i.material-icons {
    font-size: 1.125rem;
    top: -1px
}
.nav-link{
    color: white;
    padding: 0.5rem 0.7rem;
    border-radius: 1rem 0 0 1rem;
    display: flex;
    margin-top: 10px;
    span{
        margin-left: 7px;
    }
    &.active{
        background-color: #fbfbfb;
        color:#0c3529;
        text-transform: uppercase;
     }
}
.nav-link:not(.active):hover {
    color: #fbfbfb;
    background-color: #035B2F;
    font-weight: 900 !important;
    cursor: pointer;
}
.nav-item-ch{
    display: flex;
    width: 203px;
    align-items: center;
    gap: 7px;
}
.nav-item-ch-sub-1{
    display: flex;
    width: 180px;
    align-items: center;
    gap: 7px;
}
.nav-item-ch-1{
    display: inline-flex;
    align-items: center;
    gap: 6px;
}
.icon-menu{
    height: 20px;
    margin-right: 12px;
}
.icon-vector{
    display: inline-flex;
    align-items: center;
    gap: 6px;
    width: 8.571px;
    height: 10px;
}
/*.main-sidebar .nav .nav-item:hover span {*/
/*    color: $cian;*/
/*}*/
.main-sidebar .nav--no-borders .nav-item .nav-link {
    border-bottom: 0
}
.main-sidebar .nav--no-borders .dropdown-menu {
    box-shadow: inset 0 0 .4375rem rgba(61, 81, 112, .2)
}
.main-sidebar .nav--no-borders .dropdown-menu .dropdown-item:first-child {
    border-top: 1px solid #e1e5eb
}
.main-sidebar .dropdown-menu {
    position: static !important;
    -webkit-transform: translate(0) !important;
    transform: translate(0) !important;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    border: none;
    padding: 0;
    box-shadow: inset 0 -.1875rem .1875rem rgba(61, 81, 112, .08)
}
.main-sidebar .dropdown-menu .dropdown-item {
    padding: .75rem 1.75rem;
    border-bottom: 1px solid #f0f2f5;
    color: #3d5170;
    font-size: .8125rem;
    font-weight: 400
}

.main-sidebar .dropdown-menu .dropdown-item.active, .main-sidebar .dropdown-menu .dropdown-item:hover {
    color: #007bff
}

.main-sidebar .dropdown-menu .dropdown-item:hover {
    background: 0 0
}

.main-sidebar .dropdown-menu .dropdown-item.active {
    background-color: #fbfbfb
}

.main-sidebar .dropdown-menu .dropdown-item:last-of-type {
    border-bottom: 1px solid #e1e5eb
}

.main-sidebar .dropdown-menu .dropdown-divider {
    margin: 0
}

.main-sidebar .dropdown-toggle {
    position: relative
}

.main-sidebar .dropdown-toggle:after {
    /* background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuNDEgNy44NEwxMiAxMi40Mmw0LjU5LTQuNThMMTggOS4yNWwtNiA2LTYtNnoiLz48cGF0aCBkPSJNMC0uNzVoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=); */
    background-position: 50%;
    width: .875rem;
    height: .5625rem;
    transition: -webkit-transform .25s ease-in-out;
    -webkit-transition: -webkit-transform .25s ease-in-out;
    transition: transform .25s ease-in-out;
    transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
    border: none;
    position: absolute;
    top: 50%;
    right: .625rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: .1;
    will-change: transform
}

.main-sidebar .dropdown.show .dropdown-toggle:after {
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg)
}

.main-navbar__search .input-group-prepend .input-group-text, .main-sidebar__search .input-group-prepend .input-group-text {
    font-size: .6875rem;
    padding: .75rem 1.0625rem
}

.main-navbar__search input.form-control, .main-sidebar__search input.form-control {
    border: none;
    font-size: .8125rem;
    border-radius: 0
}

.main-navbar__search input.form-control:focus, .main-navbar__search input.form-control:hover, .main-sidebar__search input.form-control:focus, .main-sidebar__search input.form-control:hover {
    box-shadow: none
}

.main-navbar__logo{
    width: 120px;
}
