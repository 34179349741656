.reports-table__container {
    display: flex;
    overflow-x: auto;
    max-width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;

    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
}

.tr--no-radius th {
    border-radius: 0px !important;
}

.reports-table__container table {
    border-collapse: collapse;
    border-spacing: 0;
}

.reports-table__container td,
.reports-table__container th {
    text-wrap: nowrap;
}

.th--border,
.td--border {
    border: 1px solid #3b3b3b !important;
}

.tr--border-top {
    border-top: 1px solid #3b3b3b !important;
}

.tr--border-left {
    border-left: 1px solid #3b3b3b !important;
}

.tr--border-right {
    border-right: 1px solid #3b3b3b !important;
}

.td--shadow-right {
    box-shadow: inset -1px 0 0 #3b3b3b;
}

.tr--no-border-left {
    border-left: none !important;
}

.reports__table-bg {
    position: relative;

    border-radius: 20px;
    background-color: white;
}

.reports__table-bg::before,
.reports__table-bg::after {
    content: "";
    position: absolute;
    width: 200px;

    height: 58px;
    border: 1px solid black;
}

.reports__table-bg::before {
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;

    border-top-left-radius: 1rem;
}

.reports__table-bg::after {
    top: 0;
    right: 0;
    border-left: none;
    border-bottom: none;

    border-top-right-radius: 1rem;
}