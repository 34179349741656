:root{
    --Primario-Verde-Oscuro: #0C3529;
    --Textos-Parrafo-label: #202020;
    --Texto-finca-entrerios: #359D9E;
}
label {
    font-weight: bold;
    padding-left: 15px !important;

}

.label-login {
    padding-left: 0 !important;
    display:flex;
    align-items: start;
    flex-direction: column;
    text-transform: uppercase;
}

/* Estilos para pantallas grandes */
.titulo_principal{
    font-size: 24;
    text-transform: uppercase;
}
.titulo_parrafos {
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    padding-top: 51px;
    padding-left: 63px;
}
.subtitulo{
    font-size: 18px;
    color: #035b2f !important;
    text-transform: uppercase;
}
/* Estilos para pantallas medianas o más pequeñas */
@media (max-width: $md) {
    .titulo_principal {
        font-size: 1.2rem; /* o el tamaño que desees para pantallas más pequeñas */
    }

    .titulo_parrafos {
        font-size: 0.8rem; /* o el tamaño que desees para pantallas más pequeñas */
        padding-top: 51px; /* o el valor que desees mantener */
        padding-left: 63px; /* o el valor que desees mantener */
    }

    .subtitulo{
        font-size: 0.5rem;
    }
}

/* Seccion de finca */

.tachado {
    text-decoration: line-through;
}
.tachado-touch {
    text-decoration: line-through;
    cursor: pointer;
}

/*POPUP*/
.popup-content{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    text-wrap: avoid;
    border-radius: 0.5rem;
    white-space: initial;
    overflow: hidden;
    .popup-title{
        background: $gris-claro;
        color: $azul;
        text-align: center;
        font-weight: bold;
        padding: 5px;
    }
    .popup-text{
        color: $gris;
        padding: 5px;
        word-break: break-all;
    }
}

.bold{
    font-weight: bold;
}

.text-md{
    font-size: 1.5rem;
}

.text-lgs{
    font-size: 1.8rem;
}

.text-lg{
    font-size: 2rem;
}

.text-xs {
    font-size: 0.7rem;
}

.text-sm {
    font-size: 1rem;
}

.text-qts {
    font-size: 1.3rem;
}

/* Es el CSS para la alerta de eliminar, para el texto que aparece en la parte media. */
.text-azul-alerta{
    color: #035192;
    font-weight: 100;
}

.swal2-content{
    border: 1px solid #0c3529 !important;
    padding: 5px 15px !important;
    border-radius: 1rem !important;
    font-weight: bold !important;
    font-size: 70% !important;
    width: 50% !important;
}

.finca-entre-rios{
    color: var(--Texto-finca-entrerios);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.txt-credito-fiscal{
    color: var(--Textos-Parrafo-label);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.fn-info-sb{
    padding-left: 69px;
    color:#035B2F ;
    font-weight: 500;
    line-height: 18px; /* 112.5% */
    font-size: 16px;
    font-style: normal;
}
@media (max-width: $md) {
    .fn-info-sb{
        font-size: 10px;
    }
    .txt-credito-fiscal{
        font-size: 10px;
    }
    .finca-entre-rios{
        font-size: 12px;
    }
}