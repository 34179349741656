/* .rc-tabs-tab{
    width: 20.8rem;
} */
.rc-tabs-tab-active {
    border-top: 1px solid #0C3529 !important;
    border-right: 1px solid #0C3529!important;
    border-left: 1px solid #0C3529 !important;
    /* background-image: linear-gradient(to right, $azul 0%, $cian 100%); */
    background-color: #0C3529;
    /* width: 18rem; */
}
.rc-tabs-ink-bar {
    background-color: $gris-claro !important;
}
.rc-tabs-top .rc-tabs-tab:first-child {
    margin-left: 10px;
}

.rc-tabs-top .rc-tabs-tab {
    margin-left: 1px;
    padding: 3px 35px 3px 35px;
    color: black !important;
    font-weight: bold;
    border: 1px solid #9cb7aa ;
    border-bottom: 0 !important;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.rc-tabs-top .rc-tabs-tab-active {
    color: white !important;
}
.rc-tabs-tab:hover {
    color: black;
}
.rc-tabs-top {
    border-bottom: none;
    color: black !important;
}
.rc-tabs-bottom .rc-tabs-tab {
  padding-left: 30px;
  padding-right: 30px;
}
.rc-tabs-top .rc-tabs-bar{
    border-bottom: 0 !important;
    padding-left: 15px;
}
.rc-tabs-tab:first-child{
    /* border-radius: 1rem 0 0 0; */
    border-top-left-radius: 1rem;
}
.rc-tabs-tab:last-child{
    /* border-radius: 0 1rem 0 0; */
    border-top-right-radius: 1rem;
}
.rc-tabs.invert-tab .rc-tabs-tab:first-child{
    border-radius:0 0 0 1rem ;
}
.rc-tabs.invert-tab .rc-tabs-tab:last-child{
    border-radius: 0 0 1rem 0;
}
/* .rc-tabs.invert-tab:first-child .rc-tabs-bar{
    border-radius:0 0 0 1rem ;
}
.rc-tabs.invert-tab:last-child .rc-tabs-bar{
    border-radius: 0 0 1rem 0;
} */
.rc-tabs-tab {
    font-size: 15px !important;
    
}
.rc-tabs{
    border: none !important;
}
