@import "../../../../../style/configs/breakpoints.css";

/* Se utiliza en change password */
.reset-pass-container {
    height: 100vh;
    background-image: url("../../../../../assets/img/fondo-login.png");
    /* background-color: #55c4d4; */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /*
    /*background: linear-gradient(to right, #0062E6, #3ac1ff);*/
}

.reset-password__container {
    height: 100vh;
    background-image: url("../../../../../assets/img/fondo-login.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: safe center;

    overflow-y: auto;
}

.reset-password__form {
    min-width: 300px;
}

@media screen and (max-width: 350px) {
    .reset-password__form {
        min-width: 100%;
    }
}
