.responsive-table-fix {
    width: 50vw;
}

.index {
    z-index: 100;
    position: relative;
}

.table-no-border-fix {
    border-radius: 1rem;
}

.table-no-border-fix .table thead {
    border: 1px solid gray;
    border-radius: 0 0 1rem 1rem
}

.react-bs-table-bordered {
    border: none;

    border-radius: 1rem;
    overflow: hidden;

}

.react-bs-container-header {
    /* background: linear-gradient(to bottom, $cian 0%, $verde 100%); */
    background-color: gray;
    padding: 1px !important;
    border-radius: 1rem 1rem 0 0;
}

.table th {
    border-bottom: 0 !important;
    background: #EBEBEB;
}

th:first-child {
    border-top-left-radius: 1rem;
}

th:last-child {
    border-top-right-radius: 1rem;
}

.table td,
.table th {
    padding: .20rem .75rem;
    vertical-align: middle;
    text-align: center;
    border: none;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: $gris-claro;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: white;
}

.data-grid-container .data-grid {
    table-layout: fixed;
    border-collapse: collapse;
}

table.data-grid {
    margin: auto;
    width: 100%;
}

.data-grid-container .data-grid .cell>input {
    height: 100%;
}

.table-sm {
    font-size: 10px;
}

.table-responsive td {
    white-space: nowrap;
}

.tabla-con-borde tbody {
    border: none !important;
    border-radius: 0 0 1rem 1rem !important;
}

.tabla-con-borde td:first-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 1rem !important;
}

.tabla-con-borde td:last-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 1rem !important;
}

.tabla-adentro .react-bs-container-body tbody tr td {
    color: $negro;
}

.bg-yellow {
    background-color: #F0FFC7;
}

.celda-totales {
    background-color: #F0FFC7 !important;
    z-index: 5;
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
}

.columna-clase-total {
    white-space: normal;
    word-wrap: break-word;
}

.table-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #ddd;
}

.table-wrapper .react-bs-table {
    width: auto;
    /* Esto es clave para que la tabla ocupe solo el ancho necesario */
}

.table-wrapper::-webkit-scrollbar {
    height: 8px;
}

.table-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.table-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.celda-totales-generales {
    background-color: #7CE495;
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
    border-left: #000;
}

.cel-totales-generales-end {
    position: sticky;
    left: 170px;
    z-index: 1;
    background-color: #7CE495;
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
}

.cel-totales-generales-start {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #7CE495;
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
    border-left: 1px #000 solid !important;
    width: 11%;
    text-align: center !important;
}

/* Negrita para presupuesto de compras */
.bold-row {
    font-weight: bold;
}

.react-bootstrap-table table {
    table-layout: auto;
}

/* Para el manejo estatico del nombre de las tablas. */
.table-fixed {
    position: sticky;
    left: 0;
    z-index: 1;
}

.table-scrollable {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.table-fixed-cell-left-0 {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: inherit;
    border-right: 1px solid #ddd;
}

.table-fixed-cell-left-1 {
    position: sticky;
    left: 170px;
    z-index: 1;
    background-color: inherit;
    border-right: 1px solid #ddd;
}

.table-form-fixed-cell-left-0 {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: inherit;
    background-clip: padding-box;
    border-right: 1px solid #ddd;
    line-height: 1;
}

.table-form-shopping-fixed-cell-left-1 {
    position: sticky;
    left: 300px;
    z-index: 1;
    background-color: inherit;
    background-clip: padding-box;
    border-right: 1px solid #ddd;
}

.table-form-fixed-cell-left-1 {
    position: sticky;
    left: 250px;
    z-index: 1;
    background-color: inherit;
    background-clip: padding-box;
    border-right: 1px solid #ddd;
}

.first-column {
    width: 250px;
    min-width: 250px;
}

.first-column-shopping {
    width: 300px;
    min-width: 300px;
}